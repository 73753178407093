import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee, Details, Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationsService} from '../../../services/reservationsService'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation, navigateToCheckoutPage} from '../../../utils/navigation'
import {getLogger} from '../../../utils/getLogger'
import {checkoutService} from '../../../services/checkoutService'
import {isWixHttpError} from '../../../utils/errors'
import {trackTableReservedEvent} from '../../../utils/trackEvent'
import {EXPERIMENTS} from '../../../utils/constants'

import {isReservationSuccessful} from './untils'

export const createReservation = (flowAPI: ControllerFlowAPI) => {
  return async (
    details: Details,
    reservee: Reservee,
    reservationLocation: ReservationLocation,
    paymentTokenizationSupported: boolean,
  ) => {
    const setProps = flowAPI.controllerConfig.setProps
    const logger = getLogger(flowAPI.bi!)
    const isBusinessLocationIdExperimentEnabled = flowAPI.experiments.enabled(
      EXPERIMENTS.businessLocationId,
    )

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})

      const finishedReservation = await reservationsService.createReservation(flowAPI, {
        details,
        reservee,
      })

      // this is the only place where can get just created reservation
      if (finishedReservation?.id) {
        trackTableReservedEvent(flowAPI, finishedReservation.id, reservee)

        logger.reservationCreated({
          isPreview: false,
          reservation: finishedReservation,
          reservee,
          reservationLocation,
          isCardTokenization: paymentTokenizationSupported,
        })

        const checkoutResponse = await checkoutService.createCheckout(
          flowAPI,
          {
            reservationId: finishedReservation.id,
            reservee,
            locationId: reservationLocation.location?.id ?? undefined,
          },
          isBusinessLocationIdExperimentEnabled,
        )

        if (!checkoutResponse) {
          if (isReservationSuccessful(finishedReservation)) {
            console.error('Checkout is undefined')

            await goToReservationConfirmation(flowAPI, finishedReservation.id)

            return
          } else {
            throw new Error('Checkout is undefined')
          }
        }

        if (paymentTokenizationSupported) {
          await navigateToCheckoutPage(
            flowAPI,
            checkoutResponse.checkout!.id!,
            finishedReservation.id,
          )
        } else {
          let order

          try {
            order = await checkoutService.createOrderFromCheckout(
              flowAPI,
              checkoutResponse.checkout!.id!,
            )
          } catch (error) {
            if (isReservationSuccessful(finishedReservation)) {
              console.error('Error creating order from checkout', error)
            } else {
              throw error
            }
          }

          await goToReservationConfirmation(flowAPI, finishedReservation.id, false, order?.orderId)
        }
      } else {
        throw new Error('No reservation')
      }
    } catch (err: any) {
      if (isWixHttpError(err)) {
        setProps({
          submitReservationStatus: RequestStatus.FAILED,
          apiRequestErrorDetails: {
            applicationCode: err?.response?.data?.details?.applicationError?.code,
            message: err?.response?.data?.message,
          },
        })
        return
      }

      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: err,
      })
    }
  }
}
